import React from "react";
import { graphql } from "gatsby";
import { PrismicRichText } from "@prismicio/react";
import { StaticImage } from "gatsby-plugin-image";
import { Container, Row, Col } from "react-bootstrap";
import Seo from "../components/meta/Seo";
import Layout from "../components/common/Layout";
import { Typing } from "../components/elements/Typing";

const About = ({ data }) => {
  if (!data) return null;
  const about = data.page.data;
  // console.log(about);
  return (
    <>
      <Seo page={data.page} />
      <Layout>
        <Container
          fluid
          className="homepage about-container d-md-flex flex-column justify-content-center pt-2 ps-4 ps-sm-5 pe-4 pe-sm-7"
        >
          <Row className="about-content about-title-wrapper pt-7 pb-4">
            <StaticImage
              src="../images/react_logo.svg"
              alt="react logo"
              className="about-react-logo"
            />
            <Col>
              <h1>{about.title}</h1>
            </Col>
          </Row>
          <Row className="mb-4">
            <Typing />
          </Row>
          <Row className="about-content pb-5">
            <Col>
              <PrismicRichText field={about.about_paragraph_1.richText} />
              <PrismicRichText field={about.about_paragraph_2.richText} />
              <PrismicRichText field={about.about_paragraph_3.richText} />
              <PrismicRichText field={about.about_paragraph_4.richText} />
            </Col>
          </Row>
        </Container>
      </Layout>
    </>
  );
};

export const query = graphql`
  query aboutQuery($lang: String) {
    settings: prismicSettings(lang: { eq: $lang }) {
      ...prismicSettingsFragment
    }
    page: prismicAbout(lang: { eq: $lang }) {
      alternate_languages {
        type
        lang
      }
      lang
      type
      url
      data {
        meta_description
        meta_title
        meta_image {
          alt
          dimensions {
            height
            width
          }
          url
        }
        title
        about_paragraph_1 {
          richText
        }
        about_paragraph_2 {
          richText
        }
        about_paragraph_3 {
          richText
        }
        about_paragraph_4 {
          richText
        }
      }
    }
  }
`;

export default About;
