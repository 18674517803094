import React, { useLayoutEffect, useRef } from "react";
import { gsap } from "gsap";
import { TextPlugin } from "gsap/TextPlugin";

gsap.registerPlugin(TextPlugin);

export const Typing = () => {
  const words = [
    "Andreas.",
    "a Web Developer.",
    "a React Engineer.",
    "a Team Player",
  ];
  const typingRef = useRef();

  useLayoutEffect(() => {
    const typingCtx = gsap.context(() => {
      gsap.to(".cursor", {
        opacity: 0,
        ease: "power2.inOut",
        duration: 0.5,
        repeat: -1,
      });
      const masterTl = gsap.timeline({ repeat: -1 }).pause();
      const boxTl = gsap.timeline();
      boxTl
        .to(".box", {
          duration: 1,
          width: "108",
          delay: 0.5,
          ease: "power4.inOut",
        })
        .from(".hi", {
          duration: 1,
          y: "100",
          ease: "power3.out",
          onComplete: () => masterTl.play(),
        });
      words.forEach((word) => {
        const tl = gsap.timeline({ repeat: 1, yoyo: true, repeatDelay: 2 });
        tl.to(".text", { duration: 1.4, text: word });
        masterTl.add(tl);
      });
    }, typingRef);

    return () => {
      typingCtx.revert();
    };
  }, [words]);

  return (
    <div ref={typingRef}>
      <h3>
        <span className="box" />
        <span className="hi p-1">Hi, I'm</span>
        <span className="text" />
        <span className="cursor">_</span>
      </h3>
    </div>
  );
};
